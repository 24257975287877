import React, { useEffect, useState } from 'react'
import './AttackSurface.scss'
import { AreaChartCustom, BarChartCustom, PieChartCustom, ValuesList, WidgetBlock } from 'hub-web-lib'

import { ACTIVE_ALERTS, FINDINGS, MEAN_TIME_TO_ACKNOWLEDGE, MEAN_TIME_TO_RESOLVE, NEW_ALERTS, BY_SEVERITY, BY_TYPE } from 'js/constants/metrics/common-metrics'
import NoDataPlaceholder from 'components/Assets/NoDataMessage/NoDataPlaceholder'
import { getActiveAlertsBySeverityChartData, getActiveAlertsByTypeChartData, getByAgeChartData, getFindingsAlertsBarChartData, getFindingsAlertsValuesList, getMeanTimeChartProps, getNewAlertsChartData, getYearlyMeanTimeChartData } from 'js/ui-helpers/easm-dashboard'

const categories = [MEAN_TIME_TO_ACKNOWLEDGE, MEAN_TIME_TO_RESOLVE]

export const AttackSurface_NoAck = ({ metricsData, metricsProps, filter, isAnimationActive }) => {
    const [charts, setCharts] = useState({})

    useEffect(() => {

        const findingsAlertsValuesList = [getFindingsAlertsValuesList(metricsData)[0]] // We show only "Active alerts" here
        const { findingsAlertsBarChartData, findingsAlertsBarDetails } = getFindingsAlertsBarChartData(metricsData, metricsProps, filter)
        const { byAgeChartData, byAgeChartDataDetails } = getByAgeChartData(metricsData)
        byAgeChartData.forEach(x => delete x.Acknowledged) // We show only "Open" here, not "Acknowledged"
        const { newAlertsChartData, newAlertsChartDetails } = getNewAlertsChartData(metricsData)
        const { yearlyMeanTimeChartData, yearlyMeanTimeChartDetails } = getYearlyMeanTimeChartData(metricsData)
        const { activeAlertsBySeverityChartData, activeAlertsBySeverityChartDetails } = getActiveAlertsBySeverityChartData(metricsData, metricsProps, filter)
        const { activeAlertsByTypeChartData, activeAlertsByTypeChartDetails } = getActiveAlertsByTypeChartData(metricsData)
        const categoriesData = {}
        categories.forEach(category => {
            const { metric, chartData, chartDetails, gaugeDetails, valuesList } = getMeanTimeChartProps(metricsData, category)
            categoriesData[category] = { metric, chartData, chartDetails, gaugeDetails, valuesList }
        })
        setCharts({
            findingsAlertsValuesList,
            findingsAlertsBarChartData,
            findingsAlertsBarDetails,
            byAgeChartData,
            byAgeChartDataDetails,
            newAlertsChartData,
            newAlertsChartDetails,
            yearlyMeanTimeChartData,
            yearlyMeanTimeChartDetails,
            activeAlertsBySeverityChartData,
            activeAlertsBySeverityChartDetails,
            activeAlertsByTypeChartData,
            activeAlertsByTypeChartDetails,
            ...categoriesData
        })
    }, [])

    const findingsAlertsContent = <div className='easm-findings module-widgets-content'>
        <div className='module-widgets-content-block'>
            {charts.findingsAlertsValuesList?.length ? <ValuesList list={charts.findingsAlertsValuesList} listId='mean-time-values' /> : <NoDataPlaceholder />}
            {charts.findingsAlertsBarChartData?.length ? <>
                <h3 id='easm-findings-category'>{FINDINGS}</h3>
                <BarChartCustom chartData={charts.findingsAlertsBarChartData} chartDetails={{ ...charts.findingsAlertsBarDetails, isAnimationActive }} />
            </> : <NoDataPlaceholder />}
        </div>
    </div>

    const byAgeChartContent = <div className='easm-findings module-widgets-content'>
        <div className='module-widgets-content-block'>
            <h3 id='easm-findings-category' style={{ marginTop: 100 }} >By Age</h3>
            {charts.byAgeChartData?.length ? <BarChartCustom chartData={charts.byAgeChartData} chartDetails={{ ...charts.byAgeChartDataDetails, isAnimationActive }} /> : <NoDataPlaceholder />}
        </div>
    </div>

    const activeAlertsContent = <div className='easm-active-alerts module-widgets-content'>
        <div className='module-widgets-content-blocks'>
            <div className='module-widgets-content-block'>
                <h4 id='easm-active-by-severity-category'>{BY_SEVERITY}</h4>
                {charts.activeAlertsBySeverityChartData?.length ? <PieChartCustom chartData={charts.activeAlertsBySeverityChartData} chartDetails={{ ...charts.activeAlertsBySeverityChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />}
            </div>
            <div className='module-widgets-content-block'>
                <h4 id='easm-active-by-type-category'>{BY_TYPE}</h4>
                {charts.activeAlertsByTypeChartData?.length ? <PieChartCustom chartData={charts.activeAlertsByTypeChartData} chartDetails={{ ...charts.activeAlertsByTypeChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />}
            </div>
        </div>
    </div>

    return (
        <>
            <h2 id='attack-surface-alerts'>Alerts</h2>
            <div className='module-widgets-row grid-1-1'>
                <WidgetBlock title='' content={findingsAlertsContent} />
                <WidgetBlock title='' content={byAgeChartContent} />
            </div>
            <div className='module-widgets-row grid-2-1'>
                <WidgetBlock title={ACTIVE_ALERTS} content={activeAlertsContent} />
            </div>
            <div className='module-widgets-row area-chart'>
                <WidgetBlock title={NEW_ALERTS} content={charts.newAlertsChartData?.length ? <AreaChartCustom chartData={charts.newAlertsChartData} chartDetails={{ ...charts.newAlertsChartDetails, isAnimationActive }} /> : <NoDataPlaceholder />}  />
            </div>
        </>

    )
}

export default AttackSurface_NoAck
