import AttackSurface from 'components/Views/AttackSurface/Dashboards/AttackSurface'
import AttackSurface_NoAck from 'components/Views/AttackSurface/Dashboards/AttackSurface_NoAck'
import CyberReadiness from 'components/Views/CyberReadiness/Dashboards/CyberReadiness'
import Ddos from 'components/Views/Ddos/Dashboards/Ddos'
import PenTest from 'components/Views/PenTest/Dashboards/PenTest'
import RedTeam from 'components/Views/RedTeam/Dashboards/RedTeam'
import RiskAssessment from 'components/Views/RiskAssessment/Dashboards/RiskAssessment'
import DdosApi from 'services/DdosApi'
import EasmApi from 'services/EasmApi'
import PenTestApi from 'services/PenTestApi'
import QAApi from 'services/QAApi'
import RedTeamApi from 'services/RedTeamApi'
import RiskAssessmentApi from 'services/RiskAssessmentApi'
import { IMPACT_DIMENSION, LIKELIHOOD_DIMENSION, RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION } from './dashboard-settings'
import { ATTACK_SURFACE_MODULE, CYBER_READINESS_MODULE, DDOS_RESILIENCE_MODULE, INCIDENT_RESPONSE_MODULE, PEN_TEST_MODULE, PHISHING_SIMULATION_MODULE, RANSOMWARE_RESILIENCE_MODULE, RED_TEAM_MODULE, RISK_ASSESSMENT_MODULE } from './app-modules'
import { ddosMetricsPlaceholder, easmMetricsPlaceholder, penTestMetricsPlaceholder, qaMetricsPlaceholder, redTeamMetricsPlaceholder, riskAssessmentMetricsPlaceholder } from './enums/home-dashboard-demo'
import { ATTACK_TYPE, FINDING_CATEGORY, LAYER, MITIGATION, RISK_ASSESSMENT_DOMAIN, STATUS, TYPE } from './filter'
import { valuesPropsGeneric } from './metrics/common-metrics'
import { qaValueProps } from './metrics/qa-metrics'

let AttackSurfaceComponent = AttackSurface
const dashboardType = process.env.REACT_APP_ATTACK_SURFACE_DASHBOARD
if(dashboardType == 'NoAck') AttackSurfaceComponent = AttackSurface_NoAck

const appModulesProps = {
    [CYBER_READINESS_MODULE]: {
        component: CyberReadiness,
        api: QAApi,
        hasFilter: false,
        levelDimensions: [],
        dynamicDimensions: [],
        metricsPlaceholder: qaMetricsPlaceholder,
        isOtherString: false,
        valuesProps: qaValueProps
    },
    [ATTACK_SURFACE_MODULE]: {
        component: AttackSurfaceComponent,
        api: EasmApi,
        hasFilter: true,
        levelDimensions: [SEVERITY_DIMENSION],
        dynamicDimensions: [STATUS, TYPE],
        metricsPlaceholder: easmMetricsPlaceholder,
        isOtherString: true,
        valuesProps: valuesPropsGeneric
    },
    [RED_TEAM_MODULE]: {
        component: RedTeam,
        api: RedTeamApi,
        hasFilter: true,
        levelDimensions: [RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION, IMPACT_DIMENSION, LIKELIHOOD_DIMENSION],
        dynamicDimensions: [FINDING_CATEGORY],
        metricsPlaceholder: redTeamMetricsPlaceholder,
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [PEN_TEST_MODULE]: {
        component: PenTest,
        api: PenTestApi,
        hasFilter: true,
        levelDimensions: [RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION],
        dynamicDimensions: [FINDING_CATEGORY],
        metricsPlaceholder: penTestMetricsPlaceholder,
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [DDOS_RESILIENCE_MODULE]: {
        component: Ddos,
        api: DdosApi,
        hasFilter: true,
        levelDimensions: [RISK_LEVEL_DIMENSION],
        dynamicDimensions: [LAYER, ATTACK_TYPE, MITIGATION],
        metricsPlaceholder: ddosMetricsPlaceholder,
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [RISK_ASSESSMENT_MODULE]: {
        component: RiskAssessment,
        api: RiskAssessmentApi,
        hasFilter: true,
        levelDimensions: [RISK_LEVEL_DIMENSION, SEVERITY_DIMENSION, IMPACT_DIMENSION, LIKELIHOOD_DIMENSION],
        dynamicDimensions: [FINDING_CATEGORY, RISK_ASSESSMENT_DOMAIN],
        metricsPlaceholder: riskAssessmentMetricsPlaceholder,
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [PHISHING_SIMULATION_MODULE]: {
        component: null,
        api: null,
        hasFilter: false,
        levelDimensions: [],
        metricsPlaceholder: [],
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [RANSOMWARE_RESILIENCE_MODULE]: {
        component: null,
        api: null,
        hasFilter: false,
        levelDimensions: [],
        dynamicDimensions: [],
        metricsPlaceholder: [],
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    },
    [INCIDENT_RESPONSE_MODULE]: {
        component: null,
        api: null,
        hasFilter: false,
        levelDimensions: [],
        dynamicDimensions: [],
        metricsPlaceholder: [],
        isOtherString: false,
        valuesProps: valuesPropsGeneric
    }
}

export {
    appModulesProps
}