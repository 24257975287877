import React, { useState, createRef, useEffect, useRef } from 'react'
import { InputRow, Popup, genericTexts } from 'hub-web-lib'
import AssessmentQuestionAndAnswer from './AssessmentQuestionAndAnswer'
import AssessmentQuestion from './AssessmentQuestion'
import AssessmentApi from 'services/AssessmentApi'

const QuestionnaireQuestionsForm = ({
    questions,
    answers,
    activeIds,
    totalUnansweredQuestionsCount,
    isReview,
    isViewOnly,
    goNext = () => {},
    updateActiveQuestionId = () => {},
    saveAnswer = () => {}
}) => {
    const activeQuestionId = questions.find(q => q.id === activeIds[1])?.id || ''
    const componentRefs = useRef([])
    componentRefs.current = questions.map(q => `category-question-${q.id}`).map((_, i) => componentRefs.current[i] ?? createRef())
    useEffect(() => {
        const refIndex = questions.findIndex(q => q.id === activeIds[1])
        if (refIndex > -1 && componentRefs.current && componentRefs.current[refIndex]?.current) {
            componentRefs.current[refIndex].current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', passive: true })
        }
    }, [activeIds])
    const [remarkPopupAnswer, setRemarkPopupAnswer] = useState()
    const [currentRemarkValue, setCurrentRemarkValue] = useState()
    const [attachmentUploadAnswer, setAttachmentUploadAnswer] = useState()
    // const [attachmentFile, setAttachmentFile] = useState()

    const openRemarkPopup = (questionId) => {
        const answer = answers.find(a => a.question_id === questionId)
        setCurrentRemarkValue(answer?.remarks)
        setRemarkPopupAnswer(answer)
    }
    const saveRemark = () => {
        if(remarkPopupAnswer) remarkPopupAnswer.remarks = currentRemarkValue
        setRemarkPopupAnswer(null)
        saveAnswer()
    }
    const openAttachmentUpload = (questionId) => {
        const answer = answers.find(a => a.question_id === questionId)
        // setAttachmentFile(null)
        setAttachmentUploadAnswer(answer)
        const importMetricsRow = document.getElementById('attachmentUpload')
        importMetricsRow.click()
    }
    const changeAttachmentFile = (file) => {
        // setAttachmentFile(file)
        uploadAttachment(attachmentUploadAnswer.id, file)
    }
    const uploadAttachment = async (answerId, file) => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            let res = await AssessmentApi.uploadAssessmentResultAttachment(/* currentOrgId */ 1, 0,  answerId, formData)
            if (res.storage_name) {
                //store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                //    message: `${QUESTIONNAIRE} imported successfully`,
                //}))
                // updateTemplates()
                const answer = answers.find(a => a.id === answerId)
                if(answer) {
                    answer.attachments.push({ id: res.id, label: res.label, storage_name: res.storage_name })
                    saveAnswer()
                }
            }
            else {
                //store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                //    message: res.error,
                //    notificationType: 'error'
                //}))
                // setImportResultMessages(res.details)
            }
        }

    }

    const openAttachment = async (storage_name) => {
        const attachmentUrl = await AssessmentApi.downloadAssessmentResultAttachment(storage_name)
        if(attachmentUrl && attachmentUrl.startsWith('http')) window.open(attachmentUrl, '_blank', 'noopener,noreferrer')
    }
    const deleteAttachment = (answerId, attachmentId) => {
        if(confirm('Delete attachment?')) {
            AssessmentApi.deleteAssessmentResultAttachment(/* currentOrgId */ 1, 0,  answerId, attachmentId)
            const answer = answers.find(a => a.question_id === answerId)
            if(answer) {
                answer.attachments = answer.attachments.filter(x => x.id !== attachmentId)
                saveAnswer()
            }
        }
    }

    return (
        <div className='questions-form-wrapper scroll'>
            <div className='form questions-form'>
                {questions.length ? questions.map((question, i) => {
                    const answer = answers.find(a => a.question_id === question.id)
                    const questionNumber = i + 1
                    return (<div key={question.id} ref={componentRefs.current[i]} className={isViewOnly ? 'view-only' : 'question-form'}>
                        {isViewOnly ? <AssessmentQuestionAndAnswer
                            question={question} 
                            answers={answers}
                            questionNumber={questionNumber}  /> 
                            : <AssessmentQuestion 
                                question={question} 
                                answer={answer}
                                questionNumber={questionNumber} 
                                isActive={question.id === activeQuestionId} 
                                isLast={!totalUnansweredQuestionsCount}
                                isReview={isReview}
                                isViewOnly={isViewOnly}
                                goNext={goNext}
                                updateActiveQuestionId={updateActiveQuestionId}
                                saveAnswer={saveAnswer}
                                openRemarkPopup={openRemarkPopup}
                                openAttachmentUpload={openAttachmentUpload}
                                openAttachment={openAttachment}
                                deleteAttachment={deleteAttachment} />}
                    </div>) }
                ) : <div className='no-data-message'>No questions</div>}
                <Popup
                    isOpen={remarkPopupAnswer}
                    onClose={() => setRemarkPopupAnswer(null)}
                    title={'Remark'}
                    isDisabledClickOutside={true}>
                    <div>
                        <InputRow
                            inputId='remark'
                            inputTag='textarea'
                            layout='vertical'
                            changeValueHandler={(val) => setCurrentRemarkValue(val)}
                            defaultValue={remarkPopupAnswer?.remarks || ''} />
                        <button className='btn-submit' onClick={() => { saveRemark() }} id='save-remark'>{genericTexts.defaultText.save}</button>
                        <button onClick={() => setRemarkPopupAnswer(null)} className='btn-cancel' id='save-remark-cancel'>{genericTexts.defaultText.cancel}</button>
                    </div>
                </Popup>
                <div className='hidden-row'>
                    <InputRow
                        inputId='attachmentUpload'
                        title='Upload Attachment'
                        inputType='file'
                        changeValueHandler={changeAttachmentFile} />
                </div>
            </div>
        </div>
    )
}

export default QuestionnaireQuestionsForm
