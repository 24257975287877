import React, { useEffect, useState } from 'react'
import ImportTemplate from 'components/Modules/Questionnaire/Template/Pages/ImportTemplate'
import './TemplatesList.scss'
import { questionnaireListColumns } from 'js/constants/table-columns'
import UserApi from 'services/UserApi'
import moment from 'moment'
import { connect } from 'react-redux'
import {  ASSESSMENT, QUESTIONNAIRE } from 'js/constants/vocabulary'
import { genericTexts, SortableTable, Avatar, Loader, Popup, EllipsisText, menuActionIcons, RadioGroup } from 'hub-web-lib' // import popup
import { UPDATE_NOTIFICATION_CONTENT } from 'js/constants/action-types'
import store from 'js/store'
import { action } from 'js/actions'
import UpdateTemplate from './UpdateTemplate/UpdateTemplate'
import QuestionnaireApi from 'services/QuestionnaireApi'
import { useNavigate } from 'react-router'
import { getEmptyQuestionnaire, orderQuestionnaireItems } from 'js/ui-helpers/questionnaire'
import { getErrorDetail } from 'middlewares/ApiHelpers'
import { CYBER_READINESS_MODULE, QUESTIONNAIRES, QUESTIONNAIRES_PATH, appModulesPaths } from 'js/constants/app-modules'

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser
    }
}

const actions = {
    [genericTexts.actionsText.info]: { text: 'View Info', order: 1 },
    [genericTexts.actionsText.export]: { text: genericTexts.actionsText.exportText, order: 4 },
    [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(QUESTIONNAIRE), order: 5 }
}

const multiCheckActions = {
    [genericTexts.actionsText.delete]: { text: genericTexts.actionsText.deleteText(), icon: menuActionIcons[genericTexts.actionsText.delete] }
}

export const TemplatesListComponent = ({ currentUser }) => {
    const currentOrgId = currentUser?.current_org


    const emptyQuestionnaire = getEmptyQuestionnaire(currentUser.id, currentOrgId)

    const [isImportState, setIsImportState] = useState(false)
    const [foldersList, setFoldersList] = useState([])
    const [selectedFolder, setSelectedFolder] = useState('all')
    const [questinnaireList, setQuestinnaireList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEditTemplate, setIsEditTemplate] = useState(false)
    const [isNewQuestionnaire, setIsNewQuestionnaire] = useState(false)
    const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState('')
    const [selectedQuestionnaireIds, setSelectedQuestionnaireIds] = useState([])
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(emptyQuestionnaire)
    const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false)
    const [isOpenPermissionsPopup, setIsOpenPermissionsPopup] = useState(false)
    const [isOpenInfoPopup, setIsOpenInfoPopup] = useState(false)
    const [permissionsPopupText, setPermissionsPopupText] = useState('')
    const [orgUsers, setOrgUsers] = useState([])
    const selectedQuestionnaireDetails = questinnaireList.find(q => q.id == selectedQuestionnaireId)
    const selectedQuestionnairesCount = selectedQuestionnaireIds.length
    const navigate = useNavigate()

    const startCreateTemplate = () => {
        setSelectedQuestionnaire(emptyQuestionnaire)
        setIsNewQuestionnaire(true)
        setIsEditTemplate(true)
        setIsOpenInfoPopup(true)
    }

    const startEditTemplate = async (questionnaireId) => {
        const fullQuestionnaire = await QuestionnaireApi.getQuestionnaireTemplateFull(questionnaireId, currentOrgId)
        if (!fullQuestionnaire || fullQuestionnaire.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: fullQuestionnaire.error,
                notificationType: 'error'
            }))
            return
        }
        setSelectedQuestionnaire(orderQuestionnaireItems(fullQuestionnaire))
        setIsNewQuestionnaire(false)
        setIsEditTemplate(true)
        setIsOpenInfoPopup(true)
    }

    const closeEditQuestionnaire = () => {
        setIsEditTemplate(false)
        setSelectedQuestionnaire(emptyQuestionnaire)
        setIsOpenInfoPopup(false)
    }

    const updateQuestionnaireTemplates = async () => {
        setIsLoading(true)
        const folders = await QuestionnaireApi.getQuestionnairesFolders()
        if (!folders || folders.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: questionnaires.error,
                notificationType: 'error'
            }))
            setIsLoading(false)
            return
        }
        setFoldersList(folders)

        let questionnaires = await QuestionnaireApi.getQuestionnairesTemplates(currentOrgId)
        if (!questionnaires || questionnaires.error) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: questionnaires.error,
                notificationType: 'error'
            }))
            setIsLoading(false)
            return
        }
        for (const questionnaire of questionnaires) {
            questionnaire.createdByUser = questionnaire.created_by_user
            questionnaire.approvedByUser = questionnaire.approved_by_user
        }
        let usersData = await UserApi.getOrgUsers(currentOrgId)
        if (!usersData || usersData.error) {
            usersData = []
        }
        setOrgUsers(usersData)
        setQuestinnaireList(questionnaires)
        if (!questionnaires.length) setIsImportState(true)
        setIsLoading(false)
    }

    const menuClickHandler = (action, questionnaireId) => {
        setSelectedQuestionnaireId(questionnaireId * 1)
        const selectedQuestionnaireDetails = questinnaireList.find(q => q.id == questionnaireId)
        setPermissionsPopupText(getPermissionsPopupText(action, selectedQuestionnaireDetails))

        switch (action) {
        case genericTexts.actionsText.info:
            navigate(`/${appModulesPaths[CYBER_READINESS_MODULE]}/${QUESTIONNAIRES_PATH}/${questionnaireId}`)
            break
        case genericTexts.actionsText.edit:
            startEditTemplate(questionnaireId)
            break
        case genericTexts.actionsText.submit:
            selectedQuestionnaireDetails.approved_by === currentUser.id ? approveTemplate(questionnaireId, true) : setIsOpenPermissionsPopup(true)
            break
        case genericTexts.actionsText.groupRemove:
            selectedQuestionnaireDetails.approved_by === currentUser.id && !selectedQuestionnaireDetails.is_in_use ? approveTemplate(questionnaireId, false) : setIsOpenPermissionsPopup(true)
            break
        case genericTexts.actionsText.export:
            exportTemplate(questionnaireId)
            break
        case genericTexts.actionsText.delete:
            selectedQuestionnaireDetails.is_in_use ? setIsOpenPermissionsPopup(true) : setIsOpenDeletePopup(true)
            break
        }
    }

    const multiCheckClickHandler = (action, activityIds) => {
        setSelectedQuestionnaireIds(activityIds)
        switch (action) {
        case genericTexts.actionsText.delete:
            setIsOpenDeletePopup(true)
            break
        }
    }

    const handleTdClick = (_, questionnaireId) => {
        setSelectedQuestionnaireId(questionnaireId * 1)
        startEditTemplate(questionnaireId)
    }

    const deleteTemplate = async () => {
        setIsOpenDeletePopup(false)
        const deletedTemplate = await QuestionnaireApi.deleteQuestionnaireTemplate(selectedQuestionnaireId)
        if (!deletedTemplate || deletedTemplate.error || deletedTemplate.detail) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: deletedTemplate.error || getErrorDetail(deletedTemplate.detail),
                notificationType: 'error'
            }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
            message: genericTexts.defaultText.deletedSuccess(selectedQuestionnaireDetails?.name)
        }))
        await updateQuestionnaireTemplates()
    }

    const deleteTemplates = async () => {
        setIsOpenDeletePopup(false)
        let error = false
        for await (const id of selectedQuestionnaireIds) {
            const deletedTemplate = await QuestionnaireApi.deleteQuestionnaireTemplate(id)
            if (!deletedTemplate || deletedTemplate.error) error = true
        }
        error ? 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: genericTexts.defaultText.genericErrorMsg, notificationType: 'error' })) : 
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, { message: `${selectedQuestionnairesCount} ${QUESTIONNAIRES} ${selectedQuestionnairesCount > 1 ? 'were' : 'was'} deleted` }))
        setSelectedQuestionnaireIds([])
        await updateQuestionnaireTemplates()
    }

    const approveTemplate = async (questionnaireId, approved) => {
        const selectedTemplate = questinnaireList.find(q => q.id == questionnaireId)
        const updatedTemplate = { ...selectedTemplate, approved: approved }
        const submittedTemplate = await QuestionnaireApi.updateQuestionnaireTemplate(updatedTemplate)
        if (!submittedTemplate || submittedTemplate.error || submittedTemplate.detail) {
            store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
                message: submittedTemplate.error || getErrorDetail(submittedTemplate.detail),
                notificationType: 'error'
            }))
            return
        }
        store.dispatch(action(UPDATE_NOTIFICATION_CONTENT, {
            message: `${selectedTemplate?.name} is ${approved ? 'approved' : 'not approved'} now`
        }))
        await updateQuestionnaireTemplates()

    }

    const exportTemplate = async (questionnaireId) => {
        QuestionnaireApi.exportQuestionnaire(currentOrgId, questionnaireId)
    }

    const getActions = (q) => {
        const qActions = { ...actions }
        qActions[genericTexts.actionsText.edit] = { text: genericTexts.actionsText.editText(QUESTIONNAIRE, true, q.approved), order: 2 }
        if (!q.approved) {
            qActions[genericTexts.actionsText.submit] = { text: 'Approve', order: 3 }
        } else {
            qActions[genericTexts.actionsText.groupRemove] = { text: 'Revoke Approval', order: 3 }
        }
        return qActions
    }

    const getPermissionsPopupText = (action, questionnaire) => {
        const text = {
            [genericTexts.actionsText.delete]: { 
                title: 'Cannot Delete', 
                text: `Cannot delete this ${QUESTIONNAIRE}, it is being used by ${ASSESSMENT}.` 
            },
            [genericTexts.actionsText.submit]: { 
                title: 'Cannot Approve', 
                text: `This ${QUESTIONNAIRE} can be approved only by ${questionnaire.approvedByUser?.name}` 
            },
            [genericTexts.actionsText.groupRemove]: { 
                title: 'Cannot Revoke Approval', 
                text: questionnaire.is_in_use ? 
                    `Cannot revoke approval from this ${QUESTIONNAIRE}, it is being used by ${ASSESSMENT}.` : 
                    `This ${QUESTIONNAIRE} approval can be revoked only by ${questionnaire.approvedByUser?.name}` 
            }
        }
        return text[action]
    }

    const getTableData = () => {
        const data = questinnaireList.map(q => {
            const createdDate = q.creation_date ? moment(q.creation_date).format('DD/MM/YY') : ''
            const createdBy = q.createdByUser ? <div className='created-by'> <Avatar user={q.createdByUser} /> {q.createdByUser.name} </div> : <></>
            const manager = q.approvedByUser ? <div className='created-by'> <Avatar user={q.approvedByUser} /> {q.approvedByUser.name} </div> : <></>
            const status = q.approved ? <span className='status primary'><i className='icon-v'></i> Approved</span> : <span className='status light'>Ready to approve</span>
            const qActions = getActions(q)
            const nameEllipsed = <EllipsisText text={q.name} noBreak={true} width={170} />
            const row = {
                ...q,
                nameEllipsed,
                createdDate,
                createdBy,
                manager,
                status,
                actions: qActions,
                disableMultiCheck: q.is_in_use
            }
            return row
        })
        return data
    }

    const tableData = getTableData()

    const filterTableData = () => {
        if (selectedFolder === 'all') return tableData
        return tableData.filter(item => item.folder_id === selectedFolder)
    }

    const filteredTableData = filterTableData()

    useEffect(async () => {
        await updateQuestionnaireTemplates()
    }, [])

    const updateTemplateData = {
        questionnaire: selectedQuestionnaire,
        isOpenInfoPopup,
        setIsImportState,
        closeEditQuestionnaire,
        updateQuestionnaireTemplates,
        setIsOpenInfoPopup,
        currentOrgId,
        currentUser,
        orgUsers,
        isNewQuestionnaire
    }

    return (<div className='questionnaires-list page-content'>
        {!isImportState ?
            isLoading ? <Loader /> :
            // Display approved questionnaires
                <>
                    <div className='page-header'>
                        <h1 id='qa-questionnaires-list'>{QUESTIONNAIRE}s</h1>
                        {!isLoading && <button onClick={() => setIsImportState(true)} id='create-from-scratch'>{genericTexts.defaultText.addNewButton(QUESTIONNAIRE)}</button>}
                    </div>
                    <div className='filter-buttons-div'>
                        {foldersList.length > 1 && <><div className={`filter-switcher ${filteredTableData.length ? 'has-content' : ''}`}>
                            <RadioGroup radioGroupId='filter-switcher-group' checkedId={selectedFolder} isSwitcher={true} updateItem = { val => setSelectedFolder(val) } allItems={ [ { id: 'all', name: 'All Folders' }, ...foldersList] }/>
                        </div></>}
                    </div>

                    <div className='questionnaire-list-content'>
                        <SortableTable 
                            tableData={filteredTableData} 
                            columns={questionnaireListColumns} 
                            defaultSort={{ key: 'id' }} 
                            menuClickHandler={menuClickHandler}
                            handleTdClick={handleTdClick}
                            tableId='templates-table'
                            multiCheckActions={multiCheckActions}
                            multiCheckClickHandler={multiCheckClickHandler}
                            updateMultiCheckIds={setSelectedQuestionnaireIds}
                            multiCheckIds={selectedQuestionnaireIds} />
                    </div >
                </>
            : 
            // Import questionnaire
            <ImportTemplate
                setIsImportState={setIsImportState}
                startCreateTemplate={startCreateTemplate}
                updateTemplates={updateQuestionnaireTemplates} />}

        {/* Update / create questionnaire */}
        {isEditTemplate && <UpdateTemplate data={updateTemplateData} />}

        {/* Delete questionnaire Popup */}
        <Popup
            onClose={() => setIsOpenDeletePopup(false)}
            isOpen={isOpenDeletePopup}
            title={genericTexts.defaultText.deletePopupTitle}>
            <p>You are about to delete <EllipsisText text={selectedQuestionnaireDetails?.name} noBreak={true} /><br /> Are you sure you want to do that?</p>
            <div className='popup-buttons'>
                <button className='btn-submit' onClick={selectedQuestionnairesCount ? deleteTemplates : deleteTemplate} id='delete-qa-activity'>{genericTexts.defaultText.yes}</button>
                <button onClick={() => setIsOpenDeletePopup(false)} className='btn-cancel' id='delete-qa-activity-cancel'>{genericTexts.defaultText.no}</button>
            </div>
        </Popup>

        {/* No permissions Popup */}
        <Popup
            onClose={() => setIsOpenPermissionsPopup(false)}
            isOpen={isOpenPermissionsPopup}
            title={permissionsPopupText?.title}>
            <p>{permissionsPopupText?.text}</p>
            <div className='popup-buttons'>
                <button onClick={() => setIsOpenPermissionsPopup(false)} className='btn-submit' id='delete-qa-activity-cancel'>OK</button>
            </div>
        </Popup>
    </div>

    )
}
const TemplatesList = connect(mapStateToProps)(TemplatesListComponent)

export default TemplatesList