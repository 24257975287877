import axios from 'axios'
import { dispatchRequest, downloadFile } from 'middlewares/ApiHelpers'

import { helpers } from 'hub-web-lib'

const QuestionnaireApi = {


    getQuestionnairesFolders: async () => {
        return dispatchRequest(axios.get('/questionnaire/folders/all')).then(res => res.data)
    },

    getQuestionnairesTemplates: async (orgId) => {
        return dispatchRequest(axios.get(`/questionnaire/org/${orgId}/templates`)).then(res => res.data)
    },

    getQuestionnairesTemplatesApproved: async (orgId) => {
        return dispatchRequest(axios.get(`/questionnaire/org/${orgId}/approved-questionnaires`)).then(res => res.data)
    },

    getQuestionnaireTemplateFull: async (questionnaireId, orgId) => {
        return dispatchRequest(axios.get(`/questionnaire/org/${orgId}/templates/${questionnaireId}/full`)).then(res => res.data)
    },

    getQuestionnaireTemplate: async (questionnaireId) => {
        return dispatchRequest(axios.get(`/questionnaire/templates/${questionnaireId}`)).then(res => res.data)
    },

    getDomainsByQuestionnaireId: async (questionnaireId) => {
        return dispatchRequest(axios.get(`/questionnaire/templates/${questionnaireId}/domains`)).then(res => res.data)
    },

    // template edit

    createQuestionnaireTemplate: async (questionnaire) => {
        if (!questionnaire.creation_date) questionnaire.creation_date = new Date()
        return dispatchRequest(axios.post('/questionnaire/templates', questionnaire)).then(res => res.data)
    },

    updateQuestionnaireTemplate: async (questionnaire) => {
        return dispatchRequest(axios.put('/questionnaire/templates', questionnaire)).then(res => res.data)
    },

    deleteQuestionnaireTemplate: async (questionnaireId) => {
        return dispatchRequest(axios.delete(`/questionnaire/templates/${questionnaireId}`)).then(res => res.data)
    },

    // questionnare items edit

    createQuestionnaireItems: async (items, itemsType) => {
        itemsType = itemsType.replace('_', '-')
        return dispatchRequest(axios.post(`/questionnaire/${helpers.transformToKebab(itemsType)}`, items)).then(res => res.data)
    },

    updateQuestionnaireItems: async (items, itemsType) => {
        itemsType = itemsType.replace('_', '-')
        return dispatchRequest(axios.put(`/questionnaire/${helpers.transformToKebab(itemsType)}`, items)).then(res => res.data)
    },

    deleteQuestionnaireItems: async (itemsIds, itemsType) => {
        itemsType = itemsType.replace('_', '-')
        return dispatchRequest(axios.delete(`/questionnaire/${helpers.transformToKebab(itemsType)}`, { data: itemsIds })).then(res => res.data)
    },

    exportQuestionnaire: async(orgId, questionnaireId) => {
        downloadFile(`/files/org/${orgId}/questionnaire/${questionnaireId}/export-to-file`, 'questionnaire')
    }
}
export default QuestionnaireApi
