import axios from 'axios'
import { dispatchRequest, downloadFile } from 'middlewares/ApiHelpers'

const AssessmentApi = {

    // assessment get

    getAllAssessmentsByOrgId: async (orgId) => {
        return dispatchRequest(axios.get(`/questionnaire/org/${orgId}/assessment/summary`)).then(res => res.data)
    },

    getAssessmentResults: async (assessmentId, orgId) => {
        return dispatchRequest(axios.get(`/questionnaire/org/${orgId}/assessment/${assessmentId}/results`)).then(res => res.data)
    },

    // assessment edit

    createAssessment: async (assessment) => {
        return dispatchRequest(axios.post('/questionnaire/assessment', assessment)).then(res => res.data)
    },

    updateAssessment: async (assessment, orgId) => {
        return dispatchRequest(axios.put(`/questionnaire/org/${orgId}/assessment`, assessment)).then(res => res.data)
    },

    createAssessmentResults: async (assessmentId, orgId, answers) => {
        return dispatchRequest(axios.post(`/questionnaire/org/${orgId}/assessment/${assessmentId}/results`, answers)).then(res => res.data)
    },

    updateAssessmentResults: async (assessmentId, orgId, answers) => {
        return dispatchRequest(axios.put(`/questionnaire/org/${orgId}/assessment/${assessmentId}/results`, answers)).then(res => res.data)
    },

    submitAssessment: async (assessment_id) => {
        return dispatchRequest(axios.post(`/questionnaire/email-assessment-complete/${assessment_id}`)).then(res => res.data)
    },

    getActivityByAssessmentId: async (assessmentId) => {
        return dispatchRequest(axios.get(`/questionnaire/assessment/${assessmentId}/qa-activity`)).then(res => res.data)
    },

    getAssessmentByActivityId: async (activityId) => {
        return dispatchRequest(axios.get(`/questionnaire/assessment/qa-activity/${activityId}`)).then(res => res.data)
    },

    sendAssessmentFillRequest: async (activityId, body) => {
        return dispatchRequest(axios.post(`/questionnaire/email-assessment/${activityId}`, body)).then(res => res.data)
    },

    submitActivityWithAssessment: async (body, isEmailListChanged, recipientList) => {
        const data = {
            assessment_to_update: body,
            new_email_users: { is_email_list_changed: isEmailListChanged, old_mail_list: recipientList }
        }
        return dispatchRequest(axios.post('/questionnaire/submit-activity', data)).then(res => res.data)
    },


    generateAssessmentPredefinedResults: async (activityId) => {
        return dispatchRequest(axios.get(`/questionnaire/assessment/${activityId}/results/predefined`)).then(res => res.data)
    },
    getEmailRecipientList: async (assessmentId) => {
        return dispatchRequest(axios.get(`/questionnaire/assessment/${assessmentId}/mails`)).then(res => res.data)
    },
    uploadFile: async (orgId, assessmentId, file) => {
        const params = { entity_id: assessmentId }
        return dispatchRequest(axios.post(`/files/org/${orgId}/assessment/upload-file`, file, { params })).then(res => res.data)
    },
    exportAssessment: async (orgId, assessmentId) => {
        downloadFile(`/files/org/${orgId}/assessment/${assessmentId}/export-to-file`, 'assessment')
    },
    uploadAssessmentResultAttachment: async (orgId, assessmentId, assessmentResultId, file) => {
        return dispatchRequest(axios.post(`/questionnaire/org/${orgId}/assessment/${assessmentId}/results/${assessmentResultId}/attachment`, file)).then(res => res.data)
    },
    downloadAssessmentResultAttachment: async (storage_name) => {
        return dispatchRequest(axios.get(`/files/assessment_answer/attachment/${storage_name}`)).then(res => res.data)
    },
    deleteAssessmentResultAttachment: async (orgId, assessmentId, assessmentResultId, attachmentId) => {
        return dispatchRequest(axios.delete(`/questionnaire/org/${orgId}/assessment/${assessmentId}/results/${assessmentResultId}/attachment/${attachmentId}`)).then(res => res.data)
    },

}
export default AssessmentApi
