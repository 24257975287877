import React from 'react'
const { PEOPLE, PROCESSES, TECHNOLOGY } = require('../metrics/common-metrics')

const questionnaireAnswerTypes = [{
    id: 1,
    name: 'Multi Select',
    propName: 'multi_choice',
    icon: 'checkbox',
    hasOptions: true
}, {
    id: 2,
    name: 'One Option Choice',
    icon: 'radio_button_on',
    propName: 'possible_answers_id',
    hasOptions: true
}, {
    id: 3,
    name: 'Select from list',
    icon: 'menu_open',
    propName: 'possible_answers_id',
    hasOptions: true
}, {
    id: 4,
    name: 'Text',
    icon: 'text_fields',
    propName: 'text'
}, {
    id: 5,
    name: 'Number',
    icon: 'sort-numerically',
    propName: 'number'
}, {
    id: 6,
    name: 'One Option Choice + Alter',
    icon: 'radio_button_on',
    propName: ['possible_answers_id', 'text'],
    hasOptions: true
}]

const defaultTemplateDomains = [PEOPLE, PROCESSES, TECHNOLOGY]

const assessmentStatusesEnum = {
    pending: 1,
    inProcess: 2,
    readyToSubmit: 3,
    submitted: 4,
    approved: 5,
    cancelled: 6,
}

const assessmentStatuses = [{
    id: assessmentStatusesEnum.pending,
    statusValue: 'Pending',
    status: <span className='status warning'>Pending</span>
}, {
    id: assessmentStatusesEnum.inProcess,
    statusValue: 'In process',
    status: <span className='status light'>In process</span>
}, {
    id: assessmentStatusesEnum.readyToSubmit,
    statusValue: 'Ready to submit',
    status: <span className='status primary'>Ready to submit</span>
}, {
    id: assessmentStatusesEnum.submitted,
    statusValue: 'Submitted',
    status: <span className='status light'><span className='status primary'><i className='icon-v'></i></span> Submitted</span>
}, {
    id: assessmentStatusesEnum.approved,
    statusValue: 'Approved',
    status: <span className='status primary'>Approved</span>
}, {
    id: assessmentStatusesEnum.cancelled,
    statusValue: 'Cancelled',
    status: <span className='status light'>Cancelled</span>
}]

export {
    questionnaireAnswerTypes,
    defaultTemplateDomains,
    assessmentStatusesEnum,
    assessmentStatuses
}