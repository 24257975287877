import { InputRow, RadioGroup } from 'hub-web-lib'
import React, { useState } from 'react'
import './RadioGroupWithAlter.scss'

function RadioGroupWithAlter({ 
    controlId, 
    title,
    description,
    options,
    optionsIds,
    disabled = false,
    currentAnswer = [],
    updateItem = () => {},
}) {
    const [currentCheckedId, setCurrentCheckedId] = useState(currentAnswer[0])
    const [currentAlter, setCurrentAlter] = useState(currentAnswer[1])

    const updateCheckedId = (val) => {
        setCurrentCheckedId(val)
        if (val === optionsIds[optionsIds.length - 1]) {
            updateItem([val, currentAlter])
        } else {
            updateItem([val, null])
        }
    }
    const updateAlter = (val) => {
        setCurrentAlter(val)
        updateItem([currentCheckedId, val])
    }

    return (
        <div className='radio-group-with-alter-wrapper'>
            <RadioGroup
                radioGroupId={controlId}
                title={title}
                allItems={options}
                checkedId={currentCheckedId}
                updateItem={updateCheckedId}
                description={description}
                disabledIds={!disabled ? [] : optionsIds} />
            {currentCheckedId === optionsIds[optionsIds.length - 1] && <InputRow
                inputId={controlId + '_alt'}
                inputTag='textarea'
                placeholder='Type Your Answer'
                layout='vertical'
                defaultValue={currentAlter}
                changeValueHandler={updateAlter}
                disabled={disabled} />}
        </div>
    )
}

export default RadioGroupWithAlter